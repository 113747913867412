@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    background-color: #F0F0F0;
}

.uploadcare--progress_type_canvas {
    width: 2em;
    height: 2em;
    color: #19ba42;
    border-color: #f2f2f2;
  }
  
  .uploadcare--widget__text {
    font-size: 14px;
    line-height: 24px;
    color: #333;
  }

  .uploadcare--widget__button
  {
    -webkit-appearance: button;
    height: 48px;
    padding: 0px 24px;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: #3891ff;
    color: #fff;
    font-size: 14px;
    line-height: 48px;
    border: 0;
    text-decoration: none;
    cursor: pointer;
    margin-top: 10px;
  }

  .uploadcare--dialog__powered-by {
    display: none;
  }

  .dropzone{
    flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  }