

.App {
  text-align: center;
}

 input[type="date"] {
  position: relative;
  display: flex;
  justify-content: space-between;
}


input[type="date"]:after {
  content: url('/public/Calendar.svg'); 
  font-family: "FontAwesome";
  position: absolute;
  right: 10px;
  top: 60%;
  transform: translateY(-50%);
  color: #555;
  pointer-events: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}

input[type="date"]::-webkit-inner-spin-button {
  z-index: 1;
}

input[type="date"]::-webkit-clear-button {
  z-index: 1;
} 

.mantine-kq5u63{
  display: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.react-tel-input .form-control{
  width: 48% !important;
  height: 40px !important;
  border-color: #e5e7eb !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@media only screen and (max-width: 639px) {
  .react-tel-input .form-control{
    width: 100% !important;
  }
}
/* Calendar start */
.fc-event-main{
  background-color: #D3E4FB;
  color: #D3E4FB !important;
  border: none !important;
}
.fc-event{
  border: none;
}
.fc-scrollgrid-sync-table{
  display: none !important;
}
.fc-header-toolbar{
  display: none !important;
}
/* Calendar end */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


