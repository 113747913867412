.HomeImage{
    background-image: url("./img/foneImage.svg");
    width: 100%;
    height: 800px;
    background-size: cover;
  }
  
  .AboutUsSectionImage{
    background-image: url("./img/Section.png");
    background-size: cover;
    background-attachment: scroll;
    background-repeat: no-repeat;
  }
  
  .ContactImage{
    background-image: url("./img/Map.svg");
    background-size: cover;
    background-color: #F6F9FE;
    height: auto;
    width: 100%;
  }
  @media only screen and (max-width:768px){
    .HomeImage{
      height: auto;
    }
  }